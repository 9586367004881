import React from "react";
import tw, { styled } from "twin.macro";

const CarouselContainer = styled.div`
  ${tw`relative overflow-hidden max-h-full mt-12`}
`;

const CarouselTrack = styled.div`
  ${tw`flex transition-all duration-300`}
`;

const CarouselItem = styled.div`
  ${tw`flex-shrink-0 w-full flex items-center justify-center`}
`;

const Image = styled.img`
  ${tw`max-w-full max-h-full`}
`;

const NavButton = styled.button`
  ${tw`absolute top-1/2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2`}
  ${(props) => props.left ? tw`left-0` : tw`right-0`}
`;

const Carousel = ({ children }) => {
  const trackRef = React.useRef(null);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const totalSlides = React.Children.count(children);

  const handleNext = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
  };

  const handlePrev = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
  };

  React.useEffect(() => {
    if (trackRef.current) {
      trackRef.current.style.transform = `translateX(-${currentSlide * 100}%)`;
    }
  }, [currentSlide]);

  return (
    <CarouselContainer>
      <CarouselTrack ref={trackRef}>
        {React.Children.map(children, (child, index) => (
          <CarouselItem>
            <Image src={child.props.src} alt={child.props.alt} />
          </CarouselItem>
        ))}
      </CarouselTrack>
      <NavButton left onClick={handlePrev}>Previous</NavButton>
      <NavButton onClick={handleNext}>Next</NavButton>
    </CarouselContainer>
  );
};

export default Carousel;
