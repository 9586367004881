// import AnimationRevealPage from "../src/helpers/AnimationRevealPage";
import Header from "../src/components/headers/light"
import { FooterPOGI } from "components/footers/FooterPOGI";
import ScrollToTop from "utils/ScrollToTop";


export default ({children}) => {
  return (
    <>
      <ScrollToTop>
        <Header />
        {children}
        <FooterPOGI />
      </ScrollToTop>  
    </>
  )
};
