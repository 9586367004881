import BlogIndexPage from "../../pages/BlogIndex"
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import { Meta } from "components/meta/Meta";
import { rootURL } from "components/meta/metaConfig";

  //metaPage
  const type = "website";
  const rootURLPage = rootURL;
  const contentMeta = {
    title: 'Daftar Berita',
    metaDataText: 'Daftar Berita POGI SUMSEL',
    metaDataGoogleIMG: "/logo192.png",
    googleThumbnailImgLink: "logo192.png",
    path : "news-index",
  }

export default () => {
  return (
    <AnimationRevealPage>
      <Meta type={type} content={contentMeta} rootURL={rootURLPage} />
      <BlogIndexPage />
    </AnimationRevealPage>
  )
};
