import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import tw from "twin.macro";
import { Container, ContentWithPaddingXl} from "../components/misc/Layouts";
import { SectionHeading, Subheading as SubheadingBase } from "../components/misc/Headings";
import {SectionDescription} from "../components/misc/Typography";
import { sortContentListByDate } from "../utils/contentList/sortContentListByDate";
import { pastEventList, futureEventList } from "../utils/contentList/newListBasedToday";

import { Meta } from "components/meta/Meta";
import { rootURL } from "components/meta/metaConfig";

import { getEvent } from "services/scheduleService";

import { convertDateToLocalDateString } from "utils/timeUtils";

import LoadingPage from "components/loading/Loading";


const ContainerTable = tw.div`
  container
  mx-auto
  overflow-x-auto
  mb-4
`;

const Table = tw.table`
  w-full
  bg-white
`;

const TableHeader = tw.th`
  py-2
  px-4
  border-b
`;

const TableCell = tw.td`
  py-2
  px-4
  border-b
  text-center
`;


function TableRow({ date, dateEnd, tittle, location, time }) {
  return (
    <tr>
      <TableCell>{date}</TableCell>
      <TableCell>{dateEnd}</TableCell>
      <TableCell>{tittle}</TableCell>
      <TableCell>{location}</TableCell>
      <TableCell>{time}</TableCell>
    </tr>
  );
}

const HeadingTable = tw.h1`
  text-2xl
  font-bold
  mb-4
`;

const HeadingContainer = tw.div``
const Heading = tw(SectionHeading)``
const Subheading = tw(SubheadingBase)`text-center mb-3`
const Description = tw(SectionDescription)`mx-auto text-center`

const heading = "Jadwal Kegiatan";
const subheading = "POGI Sumatera Selatan";
const description = "Di bawah ini adalah daftar kegiatan POGI Sumatera Selatan. Untuk informasi lebih lanjut, silahkan hubungi sekretariat POGI";

  //metaPage
  const type = "website";
  const rootURLPage = rootURL;
  const contentMeta = {
    title: 'Jadwal Acara POGI SUMSEL',
    metaDataText: 'Daftar acara POGI SUMSEL',
    metaDataGoogleIMG: "/logo192.png",
    googleThumbnailImgLink: "logo192.png",
    path : "jadwal",
  }

export const Schedule = () => {
  const [error, SetError] = useState(null);
  const [eventList, setEventList] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(()=>{
    async function fetchData(){
      try {
        const data = await getEvent();
        setEventList(data); 
        setLoading(false);
      } catch(error) {
        console.log(error);
        SetError(error);
      }
    }
    fetchData()
  },[]);

  if (error) {
    navigate('/error');
  }

  if (loading) {
    return (
      <LoadingPage />
    )
  }

  // const scheduleListSorted = sortContentListByDate(contentList);
  const pastScheduleList = pastEventList(eventList);
  const futureScheduleList = futureEventList(eventList);

  const pastScheduleSorted = sortContentListByDate(pastScheduleList, "DESC");
  const futureScheduleListSorted = sortContentListByDate(futureScheduleList, "ASC");

  return (
    <AnimationRevealPage>
      <Meta content={contentMeta} type={type} rootURL={rootURLPage} />
      <Container>
        <ContentWithPaddingXl>
          <HeadingContainer>
            {subheading && <Subheading>{subheading}</Subheading>}
            {heading && <Heading>{heading}</Heading> }
            {description && <Description>{description}</Description> }
          </HeadingContainer>
        </ContentWithPaddingXl>
      </Container>
      <HeadingTable>
        {'Jadwal acara selanjutnya'.toUpperCase()}
      </HeadingTable>
      <br />
      <ContainerTable>
        <Table>
            <thead>
              <tr>
                <TableHeader>Tanggal</TableHeader>
                <TableHeader>Hingga</TableHeader>
                <TableHeader>Nama Acara</TableHeader>
                <TableHeader>location</TableHeader>
                <TableHeader>Waktu</TableHeader>
              </tr>
            </thead>
            <tbody>
            {
                futureScheduleListSorted.map((value, index) => {
                  const formattedDate = convertDateToLocalDateString(value.date, 'id-ID');
                  const formattedDateEnd = convertDateToLocalDateString(value.dateEnd, 'id-ID');
                  return (
                    <TableRow key={index} date={formattedDate} dateEnd={formattedDateEnd} tittle={value.name} location={value.location} time={value.time}  />
                  );
                }) 
            }
            </tbody>
          </Table>
      </ContainerTable>
      <br/>
      <br/>
      <HeadingTable>
        Arsip acara
      </HeadingTable>
      <br />
      <ContainerTable>
        <Table>
          <thead>
            <tr>
              <TableHeader>Tanggal</TableHeader>
              <TableHeader>Hingga</TableHeader>
              <TableHeader>Nama Acara</TableHeader>
              <TableHeader>location</TableHeader>
              <TableHeader>Waktu</TableHeader>
            </tr>
          </thead>
          <tbody>
          {
              pastScheduleSorted.map((value, index) => {
                const formattedDate = convertDateToLocalDateString(value.date, 'id-ID');
                const formattedDateEnd = convertDateToLocalDateString(value.dateEnd, 'id-ID');
                return(
                  <TableRow key={index} date={formattedDate} dateEnd={formattedDateEnd} tittle={value.name} location={value.location} time={value.time}  />
                )
              })
            }
          </tbody>
        </Table>
      </ContainerTable>
      <br />
    </AnimationRevealPage>
  )
};
