import React from "react";
import tw from "twin.macro";


const LoadingPage = () => {
  const PrimaryBackgroundContainer = tw.div`
    flex
    justify-center 
    h-screen 
    bg-white
  `;

  const SpinnerContainer = tw.div`
    flex
    flex-col
    items-center
  `;

  const Spinner = tw.div`
    border-8 
    bg-gradient-to-r 
    from-green-500 
    to-blue-500 
    rounded-full 
    h-20 
    w-20
    animate-spin
  `;

  const ChangingText = tw.div`
    mt-2
    text-2xl 
    font-semibold 
    bg-gradient-to-r 
    from-green-500 
    to-blue-500 
    bg-clip-text 
    text-transparent 
    animate-pulse
  `;

  return (
    <PrimaryBackgroundContainer>
      <SpinnerContainer>
        <Spinner />
        <ChangingText>POGI SUMSEL</ChangingText>
      </SpinnerContainer>
    </PrimaryBackgroundContainer>
  );
};

export default LoadingPage;