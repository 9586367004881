import { API_URL } from "./configService";

export async function httpGetPostEventByEventDate(skip, take) {
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
      query Query( $orderBy: [EventOrderByInput!]!, $take: Int, $skip: Int!) {
        events(orderBy: $orderBy, take: $take, skip: $skip) {
          date
          name
          postEvents {
            title
            description
            thumbnailImgLink
            googleThumbnailImgLink
            path
            categoryPostID {
              name
            }
            
          }
        }
        }
      `,
      variables: {
        "orderBy": [
          {
            "date": "desc"
          }
        ],
        "skip": skip,
        "take": take
      }
    }),
  });
  return await response.json();
}

export async function getPostEventByEventDate(skip, take){
  const data = await httpGetPostEventByEventDate(skip, take);
  const filteredData = data.data.events;
  return await filteredData;
}

export async function httpGetPostEventCounts() {
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
      query Query {
        postEventsCount
      }
      `,
    }),
  });
  return await response.json();
}

export async function getPostEventCounts(){
  const data = await httpGetPostEventCounts();
  const filteredData = await data.data.postEventsCount;
  return await filteredData;
}

export async function httpGetPostEventByPath(path) {
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
      query Query($where: PostEventWhereUniqueInput!) {
        postEvent(where: $where) {
          title
          description
          featured
          googleImageList
          googleThumbnailImgLink
          imageList
          metaDataGoogleIMG
          metaDataIMG
          metaDataText
          thumbnailImgLink
          youtubeLink
          altImg
          author {
            name
          }
          categoryPostID {
            name
          }
          eventID {
            name
            date
          }
          content {
            document
          }
          path
          tags {
            name
          }
        }
      }   
      `,
      variables: {
        where: {path: path}
      }
    }),
  });
  return await response.json();
}

export async function getPostEventByPath(path){
  const data = await httpGetPostEventByPath(path);
  const filteredData = data.data.postEvent;
  return await filteredData;
}

export async function httpGetPostEvents(skip, take) {
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
      query Query($orderBy: [PostEventOrderByInput!]!, $skip: Int!, $take: Int) {
        postEvents(orderBy: $orderBy, skip: $skip, take: $take) {
          title
          description
          thumbnailImgLink
          googleThumbnailImgLink
          path
          categoryPostID {
            name
          }
          date
        }
      }
      `,
      variables: {
        "orderBy": [
          {
            "date": "desc"
          }
        ],
        "skip": skip,
        "take": take
      }
    }),
  });
  return await response.json();
}

export async function getPostEvents(skip, take){
  const data = await httpGetPostEvents(skip, take);
  const filteredData = data.data.postEvents;
  return await filteredData;
}
