import {Helmet} from "react-helmet";
// type is either website | article
export const Meta = ({content, type, rootURL}) => {
  const {
    title,
    metaDataText,
    metaDataGoogleIMG,
    googleThumbnailImgLink,
    path,
  } = content;

  const metaImage = metaDataGoogleIMG || googleThumbnailImgLink;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={metaDataText} />

      {/* -- Open Graph / Facebook -- */}
      <meta property="og:type" content= {type} />
      <meta property="og:url" content= {`${rootURL}/${path}`} />
      <meta property="og:title" content= {title}/>
      <meta property="og:description" content= {metaDataText} />
      <meta property="og:image" content= {metaImage} />

      {/* -- Twitter -- */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content= {`${rootURL}/${path}`} />
      <meta property="twitter:title" content= {title} />
      <meta property="twitter:description"
        content= {metaDataText} />
      <meta property="twitter:image" content= {metaImage} />

    </Helmet>
  );
}

/*
// example how to use it :
const MyComponent = () => {
  const content = {
    title: "My Page Title",
    metaDataText: "This is my page",
    metaDataGoogleIMG: "image.png",
    googleThumbnailImgLink: "thumbnail.png",
    path: "/my-page",
  };

  return (
    <div>
      <Meta content={content} type="website" rootURL="https://example.com" />
    </div>
  );
};
*/