import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Layout from "./Layout";
import Home from "../src/pogiPages/Home";
import Vision from "../src/pogiPages/Vision";
import Team from "../src/pogiPages/Team";
import Member from "../src/pogiPages/Member";
import Branch from "../src/pogiPages/Branch";
import { Schedule } from "./pogiPages/Schedule";
import ErrorPage from "components/error/ErrorPage";

import NewsIndex from "./pogiPages/News/News-Index";
import NewsLayout from "pogiPages/News/NewsLayout";

import { Welcome } from "./pages/Welcome";

import { NewsletterPage } from "pages/NewsletterPage";

export default function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout children={<Home/>} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/kata-sambutan",
      children: [
        {
          path: ":slug",
          element: <Layout children={<Welcome />} />,
        }
      ],
    },
    {
      path: "visi-misi",
      element: <Layout children={<Vision/>} />,
    },
    {
      path: "berita",
      children: [
        {
          path: "news-index",
          element: <Layout children={<NewsIndex />} />,
        },
        {
          path: ":slug",
          element: <Layout children={<NewsLayout />} /> ,
        },
      ],
    },
    {
      path: "daftar-pengurus",
      element: <Layout children={<Team/>} />,
    },
    {
      path: "daftar-anggota",
      element: <Layout children={<Member/>} />,
    },
    {
      path: "jadwal",
      element: <Layout children={<Schedule/>} />,
    },
    {
      path: "contact",
      element: <Layout children={<Branch/>} />,
    },
    {
      path: "newsletter",
      element: <Layout children={<NewsletterPage/>} />,
    },
    {
      path: "error",
      element: <ErrorPage />,
    },
  ]);
  
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}
