import { API_URL } from "./configService";

export async function httpGetEvent() {
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
      query Query {
        events {
          date
          dateEnd
          name
          location
          time
        }
      }    
      `,
    }),
  });
  return await response.json();
}

export async function getEvent() {
  const data = await httpGetEvent();
  const filteredData = await data.data.events;
  return await filteredData;
}