import { parseDateFromString } from "utils/timeUtils";

const currentDate = new Date();

export function pastEventList(list) {
  return list.filter(item => {
    const itemDate = parseDateFromString(item.date);
    return itemDate <= currentDate;
  });
}

export function futureEventList(list) {
  return list.filter(item => {
    const itemDate = parseDateFromString(item.date);
    return itemDate > currentDate;
  });
}
