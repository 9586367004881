import tw from "twin.macro";

const ResponsiveVideoContainer = tw.div`relative overflow-hidden h-0 pb-[56.25%]`;

const ResponsiveIframe = tw.iframe`absolute inset-0 w-full h-full`;

const YouTubeVideo = ({ videoId }) => {
  return (
    <ResponsiveVideoContainer>
      <ResponsiveIframe
        src={videoId}
        title="YouTube Video"
        allowFullScreen
        frameBorder="0"
      ></ResponsiveIframe>
    </ResponsiveVideoContainer>
  );
};

export default YouTubeVideo;
