import { filterList } from "utils/filterList";
import { API_URL } from "./configService";

export async function httpGetVisionMission() {
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
      query VissonMissions {
        vissonMissions {
          name
          type
          content
          orderNumber
        }
      }
      `,
    }),
  });
  return await response.json();
}


export async function getVisionMissionList(field, value){
  const bulkData = await httpGetVisionMission();
  const data = await bulkData.data.vissonMissions;
  const filteredData = filterList(await data, field ,value);
  return await filteredData;
}
