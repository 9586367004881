import { API_URL } from "./configService";

export async function httpGetFooter() {
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
      query Query($orderBy: [FooterHeaderOrderByInput!]!) {
        footerHeaders(orderBy: $orderBy) {
          name
          order
          footerID {
            name
            url
          }
          
        }
      }
      `,
      variables: {
        "orderBy": [
          {
            "order": "asc"
          }
        ]
      },
    }),
  });
  return await response.json();
}

export async function getFooter() {
  const data = await httpGetFooter();
  const filteredData = await data.data.footerHeaders ;
  return await filteredData;
}

export async function httpGetSocial() {
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
      query Socials {
        categorySocials {
          name
          socialID {
            name
            order
            socialLink
          }
        }
      }
      `,
    }),
  });
  return await response.json();
}

export async function getSocial() {
  const data = await httpGetSocial();
  const filteredData = await data.data.categorySocials ;
  return await filteredData;
}
