import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Member from "../components/cards/ProfileThreeColGrid-member"
import AnimationRevealPage from "../../src/helpers/AnimationRevealPage";
import { Meta } from "components/meta/Meta";
import { rootURL } from "components/meta/metaConfig";
import { getMember } from "services/memberService";
import LoadingPage from "components/loading/Loading";
  //metaPage
  const type = "website";
  const rootURLPage = rootURL;
  const contentMeta = {
    title: 'Anggota POGI',
    metaDataText: 'Daftar Anggota POGI',
    metaDataGoogleIMG: "/logo192.png",
    googleThumbnailImgLink: "logo192.png",
    path : "daftar-anggota",
  }

export default () => {
  const [member, setMember] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(()=>{
    async function fetchData(){
      try {
        const memberList = await getMember();
        setMember(memberList);
        setLoading(false);
      } catch(err) {  
        console.log(err);
        setError(err);
      }
    }
    fetchData()
  }, []);

  if (error){
    navigate('/error');
  }

  if (loading) {
    return (
      <LoadingPage />
    )
  }
  return (
    <AnimationRevealPage>
      <Meta content={contentMeta} type={type} rootURL={rootURLPage} />
      <Member member={member} />
    </ AnimationRevealPage>
  )
};
