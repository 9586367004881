import React from "react";
import { BranchCard } from "components/cards/BranchCard";
import AnimationRevealPage from "../../src/helpers/AnimationRevealPage";
import { Meta } from "components/meta/Meta";
import { rootURL } from "components/meta/metaConfig";

  //metaPage
  const type = "website";
  const rootURLPage = rootURL;
  const contentMeta = {
    title: 'Cabang POGI SUMSEL',
    metaDataText: 'daftar cabang POGI SUMSEL',
    metaDataGoogleIMG: "/logo192.png",
    googleThumbnailImgLink: "logo192.png",
    path : "contact",
  }

export default () => {
  return (
    <AnimationRevealPage>
      <Meta content={contentMeta} type={type} rootURL={rootURLPage} />
      <BranchCard />
    </AnimationRevealPage>
  )
};
