import { API_URL } from "./configService";

export async function httpGetTeamByDepartment(type) {
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
      query Query($orderBy: [DepartmentOrderByInput!]!) {
        departments(orderBy: $orderBy) {
          name
          teams {
            name
            position
            googleDriveImgLink
            imageLink
            hierarchyID {
              name
              hierarchyRank
            }
          }
        }
      }    
      `,
      variables: {
        "orderBy": [
          {
            "order": "asc"
          }
        ]
      },
    }),
  });
  return await response.json();
}

export async function getTeamByDepartment() {
  const data = await httpGetTeamByDepartment();
  const filteredData = await data.data.departments;
  return await filteredData;
}
