import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ContentPost from "../../pages/PrivacyPolicy"
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import YouTubeVideo from "components/youtube/YouTubeVideo";
import Carousel from "components/cards/Carousel";
import { useParams } from "react-router-dom";
import { Meta } from "components/meta/Meta";
import { rootURL } from "components/meta/metaConfig";
import { getPostEventByPath } from "services/newsService";
import LoadingPage from "components/loading/Loading";

// function filterByPath(list, path) {
//   return list.find((item) => item.path === path);
// }

export default () => {
  const { slug } = useParams();
  const [post, setPost] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(()=> {
    async function fetchData() {
      try {
        const post = await getPostEventByPath(slug);
        setPost(post);
        setLoading(false);
      } catch(error) {
        console.log(error);
        setError(error);
      }
    }
    fetchData();
  },[slug]);

  if (error) {
    navigate('/error');
  }

  if (loading) {
    return (
      <LoadingPage />
    )
  }

  const {
    title,
    metaDataText,
    metaDataGoogleIMG,
    googleThumbnailImgLink,
    path,
    youtubeLink,
    altImg
  } = post?? {
    title: '',
    metaDataText: '',
    metaDataGoogleIMG: '',
    googleThumbnailImgLink: '',
    path: '',
    youtubeLink: '',
    altImg: ''
  };

  const content = {
    title,
    metaDataText,
    metaDataGoogleIMG,
    googleThumbnailImgLink,
    path,
  }

  const imageList = post?.googleImageList?.imageList ?? [];

  const document = post?.content?.document ?? [];
  
  
    //for meta data
  const type = "article";
  const rootURLPage = rootURL + '/berita'

  // const content = filterByPath(contentList, slug);

  return (
    <AnimationRevealPage>

      <Meta content={content} type={type} rootURL={rootURLPage}  />

      <ContentPost headingText={title} text={document} />

      {youtubeLink && <YouTubeVideo videoId={youtubeLink} />}
      
      <Carousel>
        {
          imageList.map((value,index) =>  (
            <img key ={index} src={value} alt={`${altImg} - ${index}`} />
          ))
        }
      </Carousel>
    </AnimationRevealPage>
  )
}
