import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as QuoteIconBase } from "images/quotes-l.svg"
import { ReactComponent as QuoteIconBase2 } from "images/quotes-r.svg"


import { SectionHeading as HeadingTitle } from "components/misc/Headings.js";

import { getVisionMissionList } from "services/visionMissionService";

import LoadingPage from "components/loading/Loading";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-green-500 text-gray-100`;

const TestimonialsSlider = tw.div`
  mt-16 mb-16 mx-auto max-w-lg sm:max-w-xl lg:max-w-4xl 
  text-left bg-gray-100 rounded-lg text-gray-900
`;
// const TestimonialsSlider = tw.div`mt-16 mb-16 mx-auto max-w-xs sm:max-w-xl lg:max-w-4xl text-left bg-gray-100 rounded-lg text-gray-900`;
const Testimonial = tw.div`px-6 py-12 sm:px-20 sm:py-16 focus:outline-none flex! flex-col justify-between h-full`
const QuoteContainer = tw.div`relative`
const QuoteIcon = tw(QuoteIconBase)`w-10 fill-current text-primary-500`
const QuoteIcon2 = tw(QuoteIconBase2)`ml-auto w-10 fill-current text-primary-500`
const Quote = tw.blockquote`font-medium sm:font-normal relative text-sm sm:text-xl text-center sm:text-center`
const QuoteList = tw.blockquote` font-bold font-medium sm:font-normal relative text-sm sm:text-xl text-center sm:text-center text-primary-500`


const HeadingInfoContainer = tw.div`flex flex-col items-center`;

export const QuoteComponent = ({content, length, index}) =>{
  return (
    <div>
      {(length === 1 || index === 0) && <QuoteIcon />}
      {length >1 && <QuoteList>[{index+1}]</QuoteList>}
      <Quote>
        {content}
      </Quote>
      {(length > 1 && index < length) && <br/>}
      {(length === 1 || index === length-1) && <QuoteIcon2 />}
      
    </div>
  );
}

export const VisionMissionBoard = () => {
  const [visions, setVisions] = useState([]);
  const [missions, setMissions] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const visionList = await getVisionMissionList('type', 'vision');
        setVisions(visionList);
        const misionList = await getVisionMissionList('type', 'mission');
        setMissions(misionList);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setError(error);
      }
    }

    fetchData();
  },[]);

  if (error) {
    navigate('/error');
    return (<div></div>)
  }

  if (loading) {
    return (
      <LoadingPage />
    )
  }
  
  return (
    <PrimaryBackgroundContainer>
    <ContentWithPaddingXl>
      <TestimonialsSlider >
        <Testimonial>
          <HeadingInfoContainer>
            <HeadingTitle>Visi</HeadingTitle>
          </HeadingInfoContainer>
          <QuoteContainer>
            {visions.map((item, index) => (
              <QuoteComponent key={index} index={index} length={visions.length} content={item.content} />
            ))}
          </QuoteContainer>
          </Testimonial>
      </TestimonialsSlider>

      <TestimonialsSlider >
        <Testimonial>
          <HeadingInfoContainer>
            <HeadingTitle>Misi</HeadingTitle>
          </HeadingInfoContainer>
      
          <QuoteContainer>
          {missions.map((item, index) => (
              <QuoteComponent key={index}  index={index} length={missions.length} content={item.content} />
            ))}
          </QuoteContainer>
          </Testimonial>
      </TestimonialsSlider>
        
    </ContentWithPaddingXl>
  </PrimaryBackgroundContainer>    
  )
}

