import React from "react";
import RestaurantLandingPage from "demos/RestaurantLandingPage";
import AnimationRevealPage from "../../src/helpers/AnimationRevealPage";
import { Meta } from "components/meta/Meta";
import { rootURL } from "components/meta/metaConfig";

  //metaPage
  const type = "website";
  const rootURLPage = rootURL;
  const contentMeta = {
    title: 'POGI SUMSEL',
    metaDataText: 'Perkumpulan Obstetri dan Ginekologi Indonesia Wilayah Sumatera Selatan',
    metaDataGoogleIMG: "/logo192.png",
    googleThumbnailImgLink: "logo192.png",
    path : "",
  }

export default () => {
  return (
    <AnimationRevealPage>
      <Meta content={contentMeta} type={type} rootURL={rootURLPage} />
      <RestaurantLandingPage />
    </ AnimationRevealPage>
  )
};
