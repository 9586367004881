import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
// import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
import { ReactComponent as InstagramIcon } from "images/instagram-icon.svg";

import logoPOGI from "../../images/logo/logo POGI.png"
import backgroundImage from "../../images/special style/SONGKET.png"

import { getFullYear } from "utils/timeUtils";

import { filterList } from "utils/filterList";

import { 
  getFooter, 
  getSocial,
} from "services/footerService";

const Container = tw.div`relative bg-gray-200 text-gray-700 -mb-8 px-8`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const SixColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;
const Column = tw.div`px-4 sm:px-0 sm:w-1/4 md:w-auto mt-12`;
const ColumnHeading = tw.h5`uppercase font-bold text-black`;

const BackgroundImage = styled.div`
  background-image: url(${backgroundImage});
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  pointer-events: none; 
  opacity: 0.1; 
`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const LinkDefault = tw.a`border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300`;

const SubscribeNewsletterColumn = tw(Column)`text-center lg:text-left w-full! lg:w-auto! mt-20 lg:mt-12`;
const SubscribeNewsletterContainer = tw.div`max-w-sm mx-auto lg:mx-0 `;
const SubscribeText = tw.p`mt-2 lg:mt-6 text-sm font-medium text-black`;
const SubscribeForm = tw.form`mt-4 lg:mt-6 text-sm sm:flex max-w-xs sm:max-w-none mx-auto sm:mx-0`;
const Input = tw.input`bg-green-100 px-6 py-3 rounded sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-green-500 focus:outline-none transition duration-300 w-full`;
const SubscribeButton = tw(PrimaryButtonBase)`
  // mt-4 sm:mt-0 w-full sm:w-auto rounded sm:rounded-l-none px-8 py-3 
`;

const Divider = tw.div`my-8 border-b-2 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-800`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-black `;


const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-900 text-gray-100 hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const currentYear = getFullYear();


export const FooterPOGI = () => {
  const [error, SetError] = useState(null);
  const [footerHeaderList, setFooterHeaderList] = useState([]);
  const [socialList, setSocialList] = useState([]);
  const navigate = useNavigate();

  useEffect(()=>{
    async function fetchData(){
      try{
        const data = await getFooter();
        const data2 = await getSocial();
        setFooterHeaderList(data);
        setSocialList(data2);
      } catch(error) {
        console.log(error);
        SetError(error);
      }
    }

    fetchData();

  },[]);

  if (error) {
    navigate('/error');
  }

  const facebookLink = filterList(socialList, "name", "Facebook")[0]?.socialID?.socialLink ?? ''
  const instagramLink = filterList(socialList, "name", "Instagram")[0]?.socialID?.socialLink ?? ''
  const youtubeLink = filterList(socialList, "name", "Youtube")[0]?.socialID?.socialLink ?? ''
  
  return (
    <Container>
      <BackgroundImage />
      <Content>
        {/* top fotter */}
        <SixColumns>
          {footerHeaderList.map((group, index) => (
            <Column key={index}>
              <ColumnHeading>{group?.name ?? ''}</ColumnHeading>
                <LinkList>
                  {group.footerID.map((item, index) => (
                    <LinkListItem key={index}>
                      <LinkDefault href={item.url}>{item.name}</LinkDefault>
                    </LinkListItem>
                  ))
                  }
                </LinkList>
            </Column>
          ))}
          <SubscribeNewsletterColumn>
            <SubscribeNewsletterContainer>
              <ColumnHeading>Daftarkan email anda pada Newsletter kami</ColumnHeading>
              <SubscribeText>
                Dapatkan informasi terkini mengenai POGI SUMSEL
              </SubscribeText>
              <SubscribeForm action="/newsletter" method="GET" >
                <Input type="email" name="email" placeholder="Your Email Address" />
                <SubscribeButton type="submit" >Subscribe</SubscribeButton>
              </SubscribeForm>
            </SubscribeNewsletterContainer>
          </SubscribeNewsletterColumn>
        </SixColumns>
        <Divider />
        {/* bottom footer */}
        <ThreeColRow>
          <LogoContainer>
            <LogoImg src={logoPOGI} />
            <LogoText>POGI SUMSEL</LogoText>
          </LogoContainer>
          <CopywrightNotice>&copy; {currentYear} POGI SUMSEL. Hak Cipta Terlindung.</CopywrightNotice>
          <SocialLinksContainer>
            <SocialLink href={facebookLink}>
              <FacebookIcon />
            </SocialLink>
            <SocialLink href={instagramLink}>
              <InstagramIcon />
            </SocialLink>
            <SocialLink href={youtubeLink}>
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow>
        <Divider />
      </Content>
    </Container>
  );
};
