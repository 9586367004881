import React from "react";
import tw from "twin.macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings";
import {SectionDescription} from "components/misc/Typography";
const HeadingContainer = tw.div``
const Heading = tw(SectionHeading)``
const Subheading = tw(SubheadingBase)`text-center mb-3`
const Description = tw(SectionDescription)`mx-auto text-center`

const ContainerTable = tw.div`
  container
  mx-auto
  overflow-x-auto
  mb-12
`;

const Table = tw.table`
  w-full
  bg-white
`;

const TableHeader = tw.th`
  py-2
  px-4
  border-b
`;

const TableCell = tw.td`
  py-2
  px-4
  border-b
`;


function TableRow({ id, name, location}) {
  return (
    <tr>
      <TableCell>{name}</TableCell>
    </tr>
  );
}

// const HeadingTable = tw.h1`
//   text-4xl
//   font-bold
//   mb-4
// `;

export default ({
  heading = "Anggota POGI SUMSEL",
  subheading = "Daftar Anggota",
  description = "Daftar di bawah ini adalah daftar anggota POGI wilayah Sumatera Selatan",
  member
}) => {
  return (
    <div>
      <Container>
        <ContentWithPaddingXl>
          <HeadingContainer>
            {subheading && <Subheading>{subheading}</Subheading>}
            {heading && <Heading>{heading}</Heading> }
            {description && <Description>{description}</Description> }
          </HeadingContainer>
        
        </ContentWithPaddingXl>
      </Container>
      <ContainerTable>
      <Table>
        <thead>
          <tr>
            <TableHeader>Nama Lengkap</TableHeader>
          </tr>
        </thead>
        <tbody>
          {
            member.map((value, index) => (
              <TableRow key={index} name={value.name}/>
            ))
          }
        </tbody>
      </Table>
    </ContainerTable>
   </div>
  );
};
