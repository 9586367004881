import { parseDateFromString } from "../timeUtils";

const compareDates = (a, b, sortCode) => {
  const dateA = parseDateFromString(a.date);
  const dateB = parseDateFromString(b.date);

  return sortCode.toUpperCase() === "DESC"? dateB - dateA : dateA - dateB; 
};

export function sortContentListByDate(list, sortCode="ASC") {
  return list.slice().sort((a,b) => compareDates(a, b, sortCode));
}
