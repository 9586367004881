import { useRouteError } from "react-router-dom";
import tw from "twin.macro";
import { Meta } from "components/meta/Meta";
import { rootURL } from "components/meta/metaConfig";

const Container = tw.div`
  flex flex-col justify-center items-center
  bg-green-500
  max-w-md mx-auto p-4
  h-screen
`;

const errorRedirection = {
  statusText: 'Error Redirection',
  message: 'Error Redirection',
}

  //metaPage
  const type = "website";
  const rootURLPage = rootURL;
  const contentMeta = {
    title: 'Error Page',
    metaDataText: 'Error Page',
    metaDataGoogleIMG: "/logo192.png",
    googleThumbnailImgLink: "logo192.png",
    path : "error",
  }


export default function ErrorPage() {
  const error = useRouteError() || errorRedirection;
  console.error(error);

  return (
    <div>
      <Meta content={contentMeta} type={type} rootURL={rootURLPage} />
      <Container id="error-page">
        <h1>Oops!</h1>
        <p>
          <span><strong>Code:  </strong></span>
          "<i>{error.status || 'unknown'}</i> "
          <span><strong>Error:  </strong></span>
          "<i>{error.statusText || error.message || 'unknown'}</i>""
        </p>
        <p>Mohon maaf atas ketidaknyamanannya.</p>
        <p>Tim POGI SUMSEL</p>
      </Container>
    </div>
  );
}