import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings";
import {SectionDescription} from "components/misc/Typography";

import organizationalStuctureIMG from "../../images/Organizational Structure.png"

import { getTeamByDepartment } from "services/teamService";

import LoadingPage from "components/loading/Loading";

const HeadingContainer = tw.div`
mb-32
`
const Heading = tw(SectionHeading)``
const Subheading = tw(SubheadingBase)`text-center mb-3`
const Description = tw(SectionDescription)`mx-auto text-center`

const Cards = tw.div`
flex 
flex-wrap 
flex-row 
justify-center 
sm:max-w-2xl 
lg:max-w-5xl 
mx-auto
`
const Card = tw.div`
mt-12 
mb-12
w-full 
sm:w-1/2 
lg:w-1/3 
flex 
flex-col 
items-center`

const CardImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`w-64 h-64 bg-contain bg-center rounded`}
`
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500 text-center`}
  }
  .name {
    ${tw`mt-1 text-xs font-medium text-gray-900 text-center`}
  9
`
const HeadingTable = tw.h1`
  text-2xl
  font-bold
  mt-4
  mb-4
`;


export const TeamBoxCard = ({teamList}) => {
  const sortedList = [...teamList].sort((a, b) => a.hierarchyID.hierarchyRank - b.hierarchyID.hierarchyRank);
  return (
    <Cards>
      {sortedList.map((team, index) => (
        <Card key={index}>
          <CardImage imageSrc={team.imageLink || team.googleDriveImgLink || ''} />
          <CardContent>
            <span className="position">{team.position || ''}</span>
            <span className="name">{team.name || ''}</span>
          </CardContent>
        </Card>
      ))}
    </Cards>
  );
}

export const DepartmentBoxCard = ({departmentData}) => {
  const {name} = departmentData || '';
  const teamList = departmentData.teams || [];
  return (
    <div>
      <HeadingTable>{name.toUpperCase()}</HeadingTable>
      <TeamBoxCard teamList = {teamList} />
    </div>
  );
}

export const TeamPages = () => {
  const [departmentList, setDepartmentList] = useState([]);
  const [error, setError] = useState(null);
  const heading = "Pengurus POGI SUMSEL";
  const subheading = "Susunan Pengurus";
  const description = "Berikut susunan pengurus POGI SUMSEL periode 2022-2025";
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData(){
      try {
        const data = await getTeamByDepartment();
        setDepartmentList(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError(error);
      }
    }

    fetchData();
  },[]);

  if (error) {
    navigate('/error');
  }

  if (loading) {
    return (
      <LoadingPage />
    )
  }
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading> }
          {description && <Description>{description}</Description> }
        </HeadingContainer>
        {departmentList.map((value, index) => (
          <DepartmentBoxCard departmentData={value}  key={index} />
        ))}

        <div id="organizationalMap">  
          <HeadingTable>
            Struktur Organisasi POGI
          </HeadingTable>
          <br />
          <br />
          <img src={organizationalStuctureIMG} alt="Organizational images" />
        </div>
      </ContentWithPaddingXl>
    </Container>
  );
};
