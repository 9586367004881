import tw from "twin.macro";
export const PrimaryButton = tw.button`
  px-8 
  py-3 
  font-bold 
  rounded 
  text-black
  hover:bg-green-700 
  hover:text-gray-200
  // hocus:text-gray-200 
  // focus:shadow-outline 
  // focus:outline-none 
  // transition duration-300
`;
