import { API_URL } from "./configService";

export async function httpGetBranch() {
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
      query Query($orderBy: [BranchOrderByInput!]!) {
        branches(orderBy: $orderBy) {
          name
          description
          order
        }
      }
      `,
      variables: {
        "orderBy": [
          {
            "order": "asc"
          }
        ]
      },
    }),
  });
  return await response.json();
}

export async function getbranch() {
  const data = await httpGetBranch();
  const filteredData = await data.data.branches;
  return await filteredData;
}
