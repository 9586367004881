import React from "react";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings";
import { DocumentRenderer } from "@keystone-6/document-renderer";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg text-gray-800`}
  
  p {
    ${tw`mt-2 leading-loose`}
  }

  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }

  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }

  h3 {
    ${tw`text-xl font-bold mt-6`}
  }

  ul,
  ol { /* Added styling for both unordered and ordered lists */
    ${tw`list-disc list-inside`}
  }

  ul li,
  ol li { /* Added styling for list items in both unordered and ordered lists */
    ${tw`ml-2 mb-3`}
  }

  ul li p,
  ol li p { /* Added styling for paragraphs inside list items */
    ${tw`mt-0 inline leading-normal`}
  }
`;

export default ({ headingText, text }) => {

  return (
  <Container>
    <ContentWithPaddingXl>
      <HeadingRow>
        <Heading>{headingText}</Heading>
      </HeadingRow>
      <Text>
        <DocumentRenderer document={text} />
      </Text>
    </ContentWithPaddingXl>
  </Container>
  );
};
