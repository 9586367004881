import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

// import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
// import { ReactComponent as HomeIcon } from "feather-icons/dist/icons/home.svg";
// import { ReactComponent as ArrowLeftIcon } from "feather-icons/dist/icons/arrow-left-circle.svg";

import logoPOGI from "../../images/logo/logo POGI.png"

import { Link } from "react-router-dom";


// const Header = tw.header`
//   flex justify-between items-center
//   bg-green-500
//   h-20
// `;

// const PageContainer = styled.div`
//   ${tw`bg-green-500`}
//   background-image: url(${headerIMGBackground});
//   background-size: cover;
//   background-position: center;
//   opacity: 0.3;
// `;



const PageContainer = styled.div`
  ${tw`bg-green-500`}
  position: relative;
  overflow: hidden;
`;


const Header = styled.header`
  ${tw`flex justify-between items-center h-20`}
  margin-left: 20px; /* Add left margin */
  margin-right: 20px; /* Add right margin */
`;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.span`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;

export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300 
`;

// items-center justify-center
const CenteredNavToggle = styled(NavToggle)`
  ${tw`flex items-center justify-center bg-yellow-500  rounded-full`}
  // width: 4 rem; /* Adjust the width as needed */
  // height: 4 rem; /* Adjust the height as needed */
`;

export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export const PrimaryButton = tw.div` 
  flex
  mb-2
  mt-2
  mx-2
  font-bold 
  rounded-full
  // hover:bg-green-700 
  hocus:text-primary-500 
  // text-green-500 
  // focus:shadow-outline 
  // focus:outline-none 
  // transition duration-300
`;

PrimaryButton.defaultProps = {
  style: {
    borderRadius: '50px'
  }
};



const MenuButton = () => {
  return (
    <PrimaryButton>
      <span style={{ display: 'flex', alignItems: 'center' }}>MENU</span>
      {/* <span><MenuIcon tw="bg-yellow-500 "/></span> */}
    </PrimaryButton>
  );
}


export default ({ roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = "lg" }) => {



  const { showNavLinks, animation, toggleNavbar, closeNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <Link to={"/"}>
      <LogoLink>
        <img src={logoPOGI} alt="logo POGI" />
          POGI SUMSEL
      </LogoLink>
    </Link>
  );

  const defaultLinks = [
    <NavLinks key={1}>
      <Link to={'/'} onClick={closeNavbar}>
        <NavLink>Beranda</NavLink> 
      </Link>
      <Link to={'/visi-misi'} onClick={closeNavbar}>
        <NavLink>Visi Misi</NavLink>
      </Link>
      <Link to={'/berita/news-index'} onClick={closeNavbar}>
        <NavLink>Berita</NavLink>
      </Link>
      <Link to={'/daftar-pengurus'} onClick={closeNavbar}>
        <NavLink>Pengurus</NavLink>
      </Link>
      <Link to={'/daftar-anggota'} onClick={closeNavbar}>
        <NavLink>Anggota</NavLink>
      </Link>
      <Link to={'/jadwal'} onClick={closeNavbar}>
        <NavLink>Jadwal Acara</NavLink>
      </Link>
      <Link to={'/contact'} onClick={closeNavbar}>
        <NavLink>Cabang</NavLink>
      </Link>
      
      {/* <NavLink href="/#" tw="lg:ml-12!">
        Login
      </NavLink>
      <PrimaryLink css={roundedHeaderButton && tw`rounded-full`}href="/#">Sign Up</PrimaryLink> */}
    </NavLinks>
  ];

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;
  

  return (
    <PageContainer>
      {/* <BackgroundImage /> */}
      <Header className={className || "header-light"}>
        <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
          {logoLink}
          {links}
        </DesktopNavLinks>

        <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
          {logoLink}
          <MobileNavLinks initial={{ x: "150%", display: "none" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
            {links}
          </MobileNavLinks>
          <CenteredNavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
            {showNavLinks ? <CloseIcon tw="w-10 h-10 bg-yellow-500" /> : <MenuButton />}
          </CenteredNavToggle>
          {/* <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
            {showNavLinks ? <CloseIcon tw="w-10 h-10" /> : <MenuIcon tw="w-10 h-10" />}
          </NavToggle> */}
        </MobileNavLinksContainer>
      </Header>
    </PageContainer>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
};
