import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import { getbranch } from "services/branchService";
import LoadingPage from "components/loading/Loading";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const responsiveIframeStyles = css`
  ${tw`w-full`}
  aspect-ratio: 16/9;
`;

const PogiMapComponent = () => (
  <div css={tw`max-w-screen-xl mx-auto`}>
    <iframe
      title={'Location of POGI'}
      src={'https://www.google.com/maps/d/u/3/embed?mid=1VwZIwFtMX5StL3uaDTupIkDy7ZGYwlc&ehbc=2E312F'}
      css={responsiveIframeStyles}
      width={800}
      height={600}
    ></iframe>
  </div>
);

export const BranchCard = ({ 
  cards = null, 
  heading = "Cabang POGI SUMSEL", 
  subheading = "Cabang", 
  description = "Perkumpulan Obstetri dan Ginekologi Indonesia (Indonesian Society of Obstetrics and Gynecology) cabang Sumatera Selatan" 
}) => {
  const [branchList, setBranchList] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(()=> {
    async function fetchData() {
      try {
        const data = await getbranch();
        setBranchList(data);
        setLoading(false);

      } catch (error) {
        console.log(error);
        setError(error);
      }
    }

    fetchData();
  },[]);

  if (error){
    navigate('/error');
  }
  if (loading) {
    return (
      <LoadingPage />
    )
  }
  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        <PogiMapComponent />
        <VerticalSpacer />
        {branchList.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="textContainer">
                <span className="title">{card.name}</span>
                <p className="description">
                  {card.description}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
