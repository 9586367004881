// import Testimonial from "components/testimonials/TwoColumnWithImage.js";
import tw from "twin.macro";
import AnimationRevealPage from "../../src/helpers/AnimationRevealPage";
import { Container, ContentWithPaddingXl} from "../components/misc/Layouts";
import { SectionHeading, Subheading as SubheadingBase } from "../components/misc/Headings";
import {SectionDescription} from "../components/misc/Typography";
import { VisionMissionBoard } from "components/testimonials/spbVision";
import { Meta } from "components/meta/Meta";
import { rootURL } from "components/meta/metaConfig";

const HeadingContainer = tw.div``
const Heading = tw(SectionHeading)``
const Subheading = tw(SubheadingBase)`text-center mb-3`
const Description = tw(SectionDescription)`mx-auto text-center`

const heading = "Visi Misi Pogi";
const subheading = "Core Values";
const description = "Berikut adalah Visi dan Misi POGI Sumatera Selatan";

  //metaPage
  const type = "website";
  const rootURLPage = rootURL;
  const contentMeta = {
    title: 'Visi dan misi POGI Sumatera Selatan',
    metaDataText: 'Daftar Visi dan Misi POGI Sumatera Selatan. Terdapat satu (1) Visi dan lima (5) misi.',
    metaDataGoogleIMG: "/logo192.png",
    googleThumbnailImgLink: "logo192.png",
    path : "visi-misi",
  }


export default () => {
  return (
    <AnimationRevealPage>
      <Meta type={type} rootURL={rootURLPage} content={contentMeta} />
      {/* <Testimonial
        subheading=""
        heading={<>Visi dan Misi</>}
      /> */}
      <Container>
        <ContentWithPaddingXl>
          <HeadingContainer>
            {subheading && <Subheading>{subheading}</Subheading>}
            {heading && <Heading>{heading}</Heading> }
            {description && <Description>{description}</Description> }
          </HeadingContainer>
        </ContentWithPaddingXl>
      </Container>
      <VisionMissionBoard />
    </AnimationRevealPage>
  )
};
