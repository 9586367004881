import { Newsletter } from "components/cards/Newsletter";
import { useLocation  } from 'react-router-dom';

export const NewsletterPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  return (
    <div>
      <Newsletter email={email} />
    </div>
  );
}