import tw from "twin.macro";

const ResponsiveDiv = tw.div`
  w-full
  min-h-[calc(100vh - 400px )]
  p-4
  mb-5
`;
export const mailChimpHTML = (email ="jagon@gmail.com") =>{
    return  `
    <div id="mc_embed_shell">
    <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css">
<style type="text/css">
      #mc_embed_signup{background:#fff; false;clear:left; font:14px Helvetica,Arial,sans-serif; width: 100%;}
      /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
         We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
</style>
<div id="mc_embed_signup">
  <form action="https://pogisumsel.us21.list-manage.com/subscribe/post?u=780afa93cef965d8a3498d7fd&amp;id=6b9d8698f6&amp;f_id=0081d5e6f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
      <div id="mc_embed_signup_scroll"><h2>Subscribe</h2>
          <div class="indicates-required"><span class="asterisk">*</span> wajib diisi</div>
          <div class="mc-field-group"><label for="mce-EMAIL">Email Address <span class="asterisk">*</span></label><input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value="${email}"></div>
      <div id="mce-responses" class="clear foot">
          <div class="response" id="mce-error-response" style="display: none;"></div>
          <div class="response" id="mce-success-response" style="display: none;"></div>
      </div>
  <div aria-hidden="true" style="position: absolute; left: -5000px;">
      /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
      <input type="text" name="b_780afa93cef965d8a3498d7fd_6b9d8698f6" tabindex="-1" value="">
  </div>
      <div class="optionalParent">
          <div class="clear foot">
              <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Subscribe">
          </div>
      </div>
  </div>
</form>
</div>
<script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script><script type="text/javascript">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]=EMAIL;ftypes[0]=merge;,fnames[1]=FNAME;ftypes[1]=merge;,fnames[2]=LNAME;ftypes[2]=merge;,fnames[3]=ADDRESS;ftypes[3]=merge;,fnames[4]=PHONE;ftypes[4]=merge;,fnames[5]=BIRTHDAY;ftypes[5]=merge;false}(jQuery));var $mcj = jQuery.noConflict(true);</script></div>

  `
  }

export function Newsletter({email}) {
  return(
    <ResponsiveDiv dangerouslySetInnerHTML={{ __html: mailChimpHTML(email) }} />
  );
}


