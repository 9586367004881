import { useState, useEffect } from "react";
import { useParams, useNavigate} from "react-router-dom";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import YouTubeVideo from "components/youtube/YouTubeVideo";
import { DocumentRenderer } from "@keystone-6/document-renderer";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionHeading } from "components/misc/Headings";
import tw from "twin.macro";
import styled from "styled-components";

import { getWelcomeDataByType } from "services/welcomeService";

import { Meta } from "components/meta/Meta";

import { rootURL } from "components/meta/metaConfig";

import LoadingPage from "components/loading/Loading";

const HeadingRow = tw.div`flex`;

const Heading = tw(SectionHeading)`text-gray-900 mb-10`;

const Text = styled.div`
  ${tw`text-lg text-gray-800`}
  
  p {
    ${tw`mt-2 leading-loose`}
  }

  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }

  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }

  h3 {
    ${tw`text-xl font-bold mt-6`}
  }

  ul,
  ol { /* Added styling for both unordered and ordered lists */
    ${tw`list-disc list-inside`}
  }

  ul li,
  ol li { /* Added styling for list items in both unordered and ordered lists */
    ${tw`ml-2 mb-3`}
  }

  ul li p,
  ol li p { /* Added styling for paragraphs inside list items */
    ${tw`mt-0 inline leading-normal`}
  }
`;


const TextPlace = styled.div`
  ${tw`my-8`}
`;

// const StyledDiv = styled.div`
//   ${tw`mb-4`}
// `;

const SimpleText = styled.p`
  ${tw`text-base`}
`;

const BoldText = styled.p`
  ${tw`text-base font-bold`}
`;


export const ContentPost = ({ headingText , document }) => {
  return (
  <Container>
    <ContentWithPaddingXl>
      <HeadingRow>
        <Heading>{`Kata Sambutan ${headingText}`}</Heading>
      </HeadingRow>
      <Text>
        <DocumentRenderer document={document} />
      </Text>
    </ContentWithPaddingXl>
  </Container>
  );
};

export const Welcome = () => {
  const { slug } = useParams();
  const [welcome, setWelcome] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const welcomeData = await getWelcomeDataByType(slug);
        setWelcome(welcomeData);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError(error)
      }
    }
    fetchData();
  }, [slug]);

  if (error) {
    navigate('/error');
  }
  
  if (loading) {
  return (
    <LoadingPage />
  )
}

  const heading = welcome?.teamID?.position ?? '';
  const content = welcome?.content?.document ?? [];
  const name = welcome?.teamID?.name ?? '';
  const youtubeLink = welcome?.youtubeLink ?? '';

  //metaPage
  const type = "website";
  const rootURLPage = rootURL;
  const contentMeta = {
    title: 'Kata Sambutan Ketua dan Ketua Umum POGI Sumatera Selatan',
    metaDataText: 'Kata Sambutan dari Ketua dan Ketua Umum POGI Sumatera Selatan',
    metaDataGoogleIMG: "/logo192.png",
    googleThumbnailImgLink: "logo192.png",
    path : "kata-sambutan",
  }

  return (
    <AnimationRevealPage>
      <Meta content={contentMeta} type={type} rootURL={rootURLPage} />
      <Container>
          <ContentWithPaddingXl>
            <HeadingRow>
              <Heading>{`Kata Sambutan ${heading}`}</Heading>
            </HeadingRow>
            {youtubeLink? (
              <YouTubeVideo videoId={youtubeLink} />
            ): null}
            <TextPlace>
            <Text>
              <DocumentRenderer document={content} />
            </Text>
            </TextPlace>
            <SimpleText>
              {`${heading} POGI SUMSEL`} 
            </SimpleText>
            <BoldText>
              {name}
            </BoldText>
          </ContentWithPaddingXl>
        </Container>
    </AnimationRevealPage>
  )
}
