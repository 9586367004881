import AnimationRevealPage from "../../src/helpers/AnimationRevealPage";
import { Meta } from "components/meta/Meta";
import { rootURL } from "components/meta/metaConfig";
import { TeamPages } from "components/cards/TeamCard";

  //metaPage
  const type = "website";
  const rootURLPage = rootURL;
  const contentMeta = {
    title: 'Pengurus POGI SUMSEL',
    metaDataText: 'Daftar Pengurus POGI SUMSEL',
    metaDataGoogleIMG: "/logo192.png",
    googleThumbnailImgLink: "logo192.png",
    path : "daftar-pengurus",
  }

export default () => {
  return (
    <AnimationRevealPage>
      <Meta content={contentMeta} type={type} rootURL={rootURLPage} />
      {/* <Team /> */}
      <TeamPages />
    </AnimationRevealPage>
  )
};
