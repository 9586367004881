import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import { Link } from "react-router-dom";
import { getPostEvents, getPostEventCounts } from "services/newsService";
import LoadingPage from "components/loading/Loading";
import { convertDateToLocalDateString } from "utils/timeUtils";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;

const Title = tw.div`
mt-1 font-black 
text-2xl 
text-gray-900 
group-hover:text-primary-500 
transition duration-300
// truncate 
overflow-hidden
h-20
`;

const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center  `;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto bg-green-500 text-white`;

// function getItemInRange(arr, start, end) {
//   if (start < 0 ||  start > end) {
//     // Invalid range
//     return [];
//   }

//   let newEnd = end;

//   if (end >= arr.length) {
//     newEnd = arr.length;
//   }

//   return arr.slice(start, newEnd);
// }


export default () => {
  const navigate = useNavigate();
  const itemPerPage = 6
  const [visible, setVisible] = useState(6);
  const [postList, setPostList] = useState([]);
  const [postCount, setPostCount] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  async function fetchLoadData(){
    const list = await getPostEvents(visible, itemPerPage);
    setPostList([
      ...postList,
      ...list
    ]);
    setVisible(v => v + itemPerPage);
  }

  useEffect(() => {
    async function fetchDataBeginning() {
      try {
        const list = await getPostEvents(0, itemPerPage);
        const totalPosts = await getPostEventCounts();
        setPostList(list);
        setPostCount(totalPosts);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError(error)
      }
    }
    fetchDataBeginning();
  },[]);

  const onLoadMoreClick = () => {
    fetchLoadData();
  };

  
  if (error) {
    navigate('/error');
  }

    if (loading) {
    return (
      <LoadingPage />
    )
  }

  return (
    <div>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>Berita POGI SUMSEL</Heading>
          </HeadingRow>
          <Posts>
            {postList.map((post, index) => (
                <PostContainer key={index} featured={false}>
                <Link to={`/berita/${post.path}`}>
                  <Post className="group" >
                    <Image imageSrc={post.thumbnailImgLink || post.googleThumbnailImgLink} />
                    <Info>
                      <Category>{post.categoryPostID.name}</Category>
                      <CreationDate>{convertDateToLocalDateString(post.date, 'id-ID')}</CreationDate>
                      <Title>{post.title}</Title>
                      {false && post.description && <Description>{post.description}</Description>}
                    </Info>
                  </Post>
                </Link>
              </PostContainer>
            ))}
          </Posts>
          {visible < postCount && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container>
    </div>
  );
};
