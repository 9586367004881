import { API_URL } from "./configService";

export async function httpGetMember() {
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
      query Query {
        members {
          name
        }
      }
      `,
    }),
  });
  return await response.json();
}

export async function getMember() {
  const data = await httpGetMember();
  const filteredData = await data.data.members;
  return await filteredData;
}