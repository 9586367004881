import { API_URL } from "./configService";

export async function httpGetWelcomeByType(type) {
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
      query Query($where: WelcomeWhereUniqueInput!) {
        welcome(where: $where) {
          type
          content {
            document
          }
          youtubeLink
          teamID {
            name
            position
            googleDriveImgLink
            imageLink
          }
        }
      }
      `,
      variables: {
        "where": {
          "type": type
        }
      },
    }),
  });
  return await response.json();
}

export async function getWelcomeDataByType(type) {
  const data = await httpGetWelcomeByType(type);
  const filteredData = await data.data.welcome;
  return await filteredData;
}
