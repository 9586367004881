import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line

import Hero from "components/hero/TwoColumnWithVideo.js";
import BlogIndexPage from "../pages/BlogIndex.js"
// import ThreeColContactDetailsCards from "components/cards/ThreeColContactDetails.js"

import { VisionMissionBoard } from "components/testimonials/spbVision.js";

import backgroundImage from '../images/special style/SONGKET.png';

import styled from "styled-components";

import { Container } from "components/misc/Layouts.js";

import { getWelcomeDataByType } from "services/welcomeService.js";

import LoadingPage from "components/loading/Loading.js";

const PrimaryBackgroundContainer = tw(Container)`
  -mx-8 
  -mt-8
  px-8 
  // bg-green-500 
  // text-gray-100`;

const BackgroundImage = styled.div`
  background-image: url(${backgroundImage});
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  pointer-events: none; 
  opacity: 0.1; 
`;
const imageCss = tw`rounded-4xl`;

  // filter: brightness(0.5);
  // filter: contrast(150%);
  // filter: grayscale(100%);
  // filter: sepia(100%);
  // backdrop-filter: blur(8px);
  // filter: hue-rotate(90deg);
  // z-index: -1;

  // @media screen and (max-width: 768px) {
  // background-image: url(${backgroundImage});
  // }

export default () => {
  const [ketua, setKetua] = useState({});
  // const [wakil, setWakil] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData(){
      try{
        const sambutanKetua = await getWelcomeDataByType('ketua');
        setKetua(sambutanKetua);
        setLoading(false);
        // const sambutanWakil = await getWelcomeDataByType('wakil');
        // setWakil(sambutanWakil);
      } catch (error) {
        console.log(error);
        setError(error)
      }
    }
    fetchData();
  },[]);

  if (error) {
    navigate('/error');
    return (<div></div>)
  }

  if (loading) {
    return (
      <LoadingPage />
    )
  }

  
  const ketuaName = ketua?.teamID?.name ?? '';
  const ketuaPosition = ketua?.teamID?.position ?? '';
  const ketuaYoutubeLink = ketua?.youtubeLink ?? '';
  let ketuaImage = ketua?.teamID?.imageLink ?? '';
  if (!ketuaImage) {
    ketuaImage = ketua?.teamID?.googleDriveImgLink ?? '';
  }

  // const wakilName = wakil?.teamID?.name ?? '';
  // const wakilPosition = wakil?.teamID?.position ?? '';
  // const wakilYoutubeLink = wakil?.youtubeLink ?? '';
  // let wakilImage = wakil?.teamID?.imageLink ?? wakil?.teamID?.googleDriveImgLink ?? '';
  // if (!wakilImage) {
  //   wakilImage = wakil?.teamID?.googleDriveImgLink ?? '';
  // }

  return (
    <div>  
      <PrimaryBackgroundContainer>
        <BackgroundImage />
        <Hero
          name = {ketuaName}
          position= {ketuaPosition}
          description="👉 Kata Sambutan ... "
          imageSrc={ketuaImage}
          imageCss={imageCss}
          imageDecoratorBlob={true}
          primaryButtonText="Order Now"
          watchVideoButtonText="Video Kata Sambutan"
          path = "ketua"
          watchVideoYoutubeUrl = {ketuaYoutubeLink}
        />
      </PrimaryBackgroundContainer>

      {/* <Hero
        name = {wakilName}
        position= {wakilPosition}
        description="👉 Kata Sambutan ... "
        imageSrc={wakilImage}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Order Now"
        watchVideoButtonText="Video Kata Sambutan"
        path = "wakil"
        watchVideoYoutubeUrl = {wakilYoutubeLink}
      /> */}

      <VisionMissionBoard />
      <BlogIndexPage />
    </div>
  );
}
